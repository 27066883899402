<template>
    <div class="M2-slider" @mouseout="hoverIndex=''">
        <div v-for="item in listData" :key="item" @mouseenter="handleHover(item)" @click="handleClick(item)" :style="point == item?'background: rgb(220, 220, 220,0.5);':''">
            <div class="num">{{item/10}}</div>
            <div class="line" v-show="point == item" style="opacity:0.5">
                <img src="../../../assets/images/credit/credit-Model/组 64@2x.png"/>
            </div>
            <div class="show" v-show="clickIndex == item">
                <img src="../../../assets/images/credit/credit-Model/联合 9@2x.png"/>
                <div>{{item/10}}</div>
            </div> 
            <div class="line" v-show="clickIndex == item" style="z-index:1;">
                <img src="../../../assets/images/credit/credit-Model/组 64@2x.png"/>
            </div>
            <div class="show" v-show="hoverIndex == item">
                <img src="../../../assets/images/credit/credit-Model/联合 9@2x.png"/>
                <div>{{item/10}}</div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props:['choseIndex','setIndex'],
    data(){
        return {
            listData:[
                    100,
                    97,
                    93,
                    90,
                    87,
                    83,
                    80,
                    77,
                    73,
                    70,
                    67,
                    63,
                    60,
                    57,
                    53,
                    50,
                    47,
                    43,
                    40,
                    37,
                    33,
                    30,
                    27,
                    23,
                    20,
            ],
            clickIndex:'',
            hoverIndex:'',
            point:0
        }         
    },
    watch:{ 
        $route(){ 
            var entName = localStorage.getItem('dupontEntName')
            var entData = JSON.parse(localStorage.getItem('dupontEntData'+entName))
            this.point = entData.M1Point3
        },
        choseIndex(){
            this.clickIndex = this.choseIndex
        }
    },
    mounted(){
        this.clickIndex = this.choseIndex
        var entName = localStorage.getItem('dupontEntName')
        var entData = JSON.parse(localStorage.getItem('dupontEntData'+entName))
        this.point = entData.M1Point3
    },
    methods:{ 
        handleClick(item){
            this.$emit('update:choseIndex',item)
            this.clickIndex = item
            var entName = localStorage.getItem('dupontEntName')
            var entData = JSON.parse(localStorage.getItem('dupontEntData'+entName))
            this.point = entData.M1Point3
            var arr = entData.m2point?entData.m2point:[]
            arr[this.setIndex] = item
            entData.m2point = arr
            localStorage.setItem('dupontEntData'+entName,JSON.stringify(entData))
        },
        handleHover(item){
            this.hoverIndex = item
        }
    }
}
</script>
<style lang="less" scoped>
.M2-slider{
    width: 100%; 
    height: 22px;
    border: 1px solid #DCDCDC;
    background: linear-gradient(90deg, #19BE6B 0%, #86D923 42%, #FF4D4F 100%);
    opacity: 1;
    border-radius: 11px;
    display: flex;
    margin-top: 40px;
    margin-bottom: 20px;
    >div{
        width: 5.8%;
        height: 100%;
        border-right: 1px solid #dcdcdc;
        transition: all ease 0.2s;
        cursor: pointer;
        position: relative;
        >.num{
            position: absolute;
            top: -20px;
            left: 50%;
            transform: translateX(-50%);
            font-size: 12px;
            font-weight: 400;
            line-height: 20px;
            color: #30343D;
            white-space:nowrap;
        }
        >.line{
            width: 39px;
            height: 20px;
            // background: linear-gradient(90deg, #19BE6B 0%, #12884D 100%);
            border-radius: 10px;
            position: absolute;
            bottom: -20px;
            left: 50%;
            transform: translateX(-50%);
            >img{
                width: 100%;
                height:100%;
            }
        }
        >.show{
            width:35px;
            height:35px;
            background: #F6F6F6;
            position: absolute;
            top: -35px;
            left: 50%;
            transform: translateX(-50%);
            >div{
                position: relative;
                z-index: 1; 
                text-align: center;
                line-height: 32px;
                color: #fff;
            }
            >img{ 
                width: 100%;
                height:100%;
                position: absolute;
                left: 0;
            }
        }
        &:last-child{
            border-right: none;
        }
        &:hover{
            opacity: 0.5;
            background: #DCDCDC;
        }
    }
}
</style>