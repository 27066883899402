<template>
    <div class="M2-M3-jjhj">
        <div class="M2-M3-content">

            <div class="M2-M3-jjhj-chart"> 
                <div class="M2-M3-jjhj-title">所属行业信息</div>
                <el-table   
                :data="tableData"
                border 
                style="width: 100%;margin-top:10px;"> 
                    <el-table-column 
                    prop="fl" 
                    label="行业分类"
                    align="center"
                    width="80">
                    </el-table-column> 
                    <el-table-column
                    prop="sy"
                    align="center"
                    min-width="200"
                    label="主营业务"
                    >
                    </el-table-column>
                    <el-table-column
                    prop="c27"
                    align="center"
                    width="80"
                    label="C27">
                    </el-table-column>
                </el-table>
                <div class="M2-M3-jjhj-title" style="margin-top:20px;">行业景气指数</div>
                <div id="zx-chart" :style="{width: '100%', height: '600px'}"></div>
                <div class="M2-M3-jjhj-title" style="margin-top:20px;">行业资讯</div>
                <el-table
                :data="tableData1"
                border
                style="width: 100%;margin-top:10px;">
                    <el-table-column
                    prop="newType"
                    label="新闻类型"
                    align="center"
                    width="80">
                    </el-table-column>
                    <el-table-column
                    prop="title"
                    align="center"
                    min-width="200"
                    label="标题"
                    > 
                    </el-table-column>
                    <el-table-column
                    prop="author"
                    align="center"
                    width="180"
                    label="来源">
                    </el-table-column>
                </el-table>
            </div>

            <div class="M2-M3-jjhj-Edit">
                <div class="credit-title">
                    <div>
                    <div class="iconImg">
                        <img src="../../../assets/images/credit/credit-Model/经济@2x.png">
                    </div>
                    <span>行业环境</span> 
                    </div>
                    <div>
                    <div class="credit-title-todetail" ></div>
                    </div>
                </div>

                <div class="M2-M3-jjhj-title">1.行业定位- 行业及相关产业政策</div>

                <m-slider :choseIndex.sync="slider1" :setIndex="1"></m-slider>
    
                <div style="margin-top:40px;margin-bottom:20px;">
                    <el-input
                        type="textarea"
                        :rows="5"
                        placeholder="（请分析师对该指标信用评分进行解释说明）"
                        v-model="textarea">
                    </el-input>
                </div> 

                <div class="M2-M3-jjhj-title">2.发展程度</div>
                <div class="M2-M3-jjhj-title" style="font-size:14px">2.1  行业所处发展阶段</div>

                <m-slider :choseIndex.sync="slider2" :setIndex="2"></m-slider>

                <div class="M2-M3-jjhj-title" style="font-size:14px">2.2  行业规范程度</div>

                <m-slider :choseIndex.sync="slider3" :setIndex="3"></m-slider>
    
                <div style="margin-top:40px;margin-bottom:20px;">
                    <el-input
                        type="textarea"
                        :rows="5"
                        placeholder="（请分析师对该指标信用评分进行解释说明）"
                        v-model="textarea2">
                    </el-input>
                </div>  

                
                <div class="M2-M3-jjhj-title">3.行业竞争</div>
                <div class="M2-M3-jjhj-title" style="font-size:14px">3.1  行业准入难度</div>
 
                <m-slider :choseIndex.sync="slider4" :setIndex="4"></m-slider>

                <div class="M2-M3-jjhj-title" style="font-size:14px">3.2  行业市场集中度</div>

                <m-slider :choseIndex.sync="slider5" :setIndex="5"></m-slider>
    
                <div style="margin-top:40px;margin-bottom:20px;">
                    <el-input
                        type="textarea"
                        :rows="5"
                        placeholder="（请分析师对该指标信用评分进行解释说明）"
                        v-model="textarea3">
                    </el-input>
                </div> 

            </div> 
        </div>
        <div class="M2-M3-jjhj-buttonList">
            <el-button type="primary" size="small" @click="save">保存</el-button>
            <el-button size="small" @click="routerTo('M2toM3JJHJ')" icon="el-icon-arrow-left">上一页</el-button>
            <el-button size="small" @click="routerTo('M2toM3QYSZ')">下一页<i class="el-icon-arrow-right el-icon--right"></i></el-button>
        </div>
    </div>
</template>
<script>
import MSlider from '../component/M2-slider'
// import { getHy,getYqlist } from '@@/api/dupont'
export default { 
    name:'M2toM3HYHJ',
    components:{MSlider},
    data() {
      return {
        tableData: [{
            fl:"",
            sy:"",
            code:"",
            c27:"",
        }],
        tableData1: [],
        textarea:'',
        textarea2:'',
        textarea3:'',
        slider1:'',
        slider2:'',
        slider3:'',
        slider4:'',
        slider5:'',


        chartData:[],
        nicTypeList:[
            {
                name:'制造类',
                value:'4',
                trueV:'4'
            },
            {
                name:'工程类',
                value:'1',
                trueV:'1'
            },
            {
                name:'贸易类',
                value:'3',
                trueV:'3'
            },
            {
                name:'科学研究技术服务',
                value:'2',
                trueV:'2'
            },
            {
                name:'信息传输和软件技术',
                value:'6',
                trueV:'1'
            }, 
            {
                name:'其他',
                value:'5',
                trueV:'1'
            },
        ],
      }
    }, 
    // watch:{ 
    //     $route(to,from){
    //         for(let i in this.routeTags){
    //             if(this.routeTags[i].meta.name == from.meta.name){
    //                 this.$store.commit("delRouteTags", i); 
    //             }
    //         }
    //     }
    // }, 
    mounted(){ 
        var entName = localStorage.getItem('dupontEntName')
        var entData = JSON.parse(localStorage.getItem('dupontEntData'+entName))

        var data = JSON.parse(entData.m1JsonData)
        var nic = this.getNicName(data.nicType)
        this.tableData[0].fl = nic.name
        this.tableData[0].sy = data.zyyw
        this.getEchart(nic)
 
        var arr = entData.m2point?entData.m2point:[]
        this.slider1 = arr[1]?arr[1]:entData.M1Point3
        this.slider2 = arr[2]?arr[2]:entData.M1Point3
        this.slider3 = arr[3]?arr[3]:entData.M1Point3
        this.slider4 = arr[4]?arr[4]:entData.M1Point3
        this.slider5 = arr[5]?arr[5]:entData.M1Point3

        this.textarea=entData.hyhjText1
        this.textarea=entData.hyhjText2
        this.textarea=entData.hyhjText3
        // if(localStorage.getItem('hyhjText1')!=undefined)
        //     this.textarea=localStorage.getItem('hyhjText1')
        // if(localStorage.getItem('hyhjText2')!=undefined)
        //     this.textarea2=localStorage.getItem('hyhjText2')
        // if(localStorage.getItem('hyhjText3')!=undefined)
        //     this.textarea3=localStorage.getItem('hyhjText3')

    },
    methods:{
        getListYq(){
            this.$axios({
                url: "/dupont/yqlist",
                method: "post",
                data: {},
            }).then(res=>{
                this.tableData1 = res
            })
        },
        getNicName(value){
            var item = this.nicTypeList.find(item=>{
                return item.value == value
            })
            return item?item:'' 
        },
        getEchart(nic){
            this.$axios({
                url: "/dupont/getHy",
                method: "post",
                data: {},
            }).then(res=>{
                var day = []
                var value = []
                if(nic.trueV == '4'){
                    for(let i in res){
                        day.push(res[i].time)
                        value.push(res[i].zhizao?res[i].zhizao:0)
                    }
                }else if(nic.trueV == '3'){
                    for(let i in res){
                        day.push(res[i].time)
                        value.push(res[i].pifa?res[i].pifa:0)
                    }
                }else if(nic.trueV == '2'){
                    for(let i in res){
                        day.push(res[i].time)
                        value.push(res[i].kexue?res[i].kexue:0)
                    }
                }else if(nic.trueV == '1'){
                    for(let i in res){
                        day.push(res[i].time)
                        value.push(res[i].xinxi?res[i].xinxi:0)
                    }
                }
 
                this.draw(day,value)
            })
            this.getListYq()
        },
        save(){
            var entName = localStorage.getItem('dupontEntName')
            var entData = JSON.parse(localStorage.getItem('dupontEntData'+entName))
            this.$message({
                message: this.$store.state.saveText,
                type: 'success' 
            });
            entData.m2point = entData.m2point?entData.m2point:[]
            entData.m2point[1] = entData.m2point[1]?entData.m2point[1]:entData.M1Point3
            entData.m2point[2] = entData.m2point[2]?entData.m2point[2]:entData.M1Point3
            entData.m2point[3] = entData.m2point[3]?entData.m2point[3]:entData.M1Point3
            entData.m2point[4] = entData.m2point[4]?entData.m2point[4]:entData.M1Point3
            entData.m2point[5] = entData.m2point[5]?entData.m2point[5]:entData.M1Point3

            entData.hyhjText1 = this.textarea
            entData.hyhjText2 = this.textarea2
            entData.hyhjText3 = this.textarea3
            localStorage.setItem('dupontEntData'+entName,JSON.stringify(entData))
            
            // localStorage.setItem('hyhjText1',this.textarea)
            // localStorage.setItem('hyhjText2',this.textarea2)
            // localStorage.setItem('hyhjText3',this.textarea3)
        },
        routerTo(name){
            this.save()
            this.$router.push({name:name})
        },
        draw(day,value){ 
            let myChartHis = this.$echarts.init(
                document.getElementById(`zx-chart`)
            );
            let option = {
               xAxis: {
                    type: 'category',
                    data: day
                },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        label: {
                            backgroundColor: '#6a7985'
                        }
                    }
                },
                yAxis: {
                    type: 'value'
                },
                series: [{
                    data: value,
                    type: 'line',
                    smooth: true
                }]
            };

            myChartHis.setOption(option);

            setTimeout(function () {
                window.onresize = function () {
                    myChartHis.resize();
                };
            }, 200);
        }
    }
}
</script>
<style lang="less" scoped>
@media screen and (max-width: 1300px) {
    .M2-M3-content{
        height: 100%;
    } 
    .M2-M3-jjhj-chart,.M2-M3-jjhj-Edit{
        height: calc(~"100%");
    }
 
}
@media screen and (min-width: 1300px) {
    .M2-M3-content{
         height: calc(~"100vh - 236px");
     }
    .M2-M3-jjhj-chart,.M2-M3-jjhj-Edit{
        overflow-x: hidden;
    //   height: calc(~"100vh - 176px");
    } 
}
.M2-M3-jjhj{
    width: 100%; 
    height: 100%;
    // display: flex;
    // justify-content: space-between;
    // flex-wrap: wrap;
    >.M2-M3-content{
        // height: calc(~"100vh - 236px");
        display: flex;
        justify-content: space-between;
    }
    .M2-M3-jjhj-title{
        font-size: 16px;
        font-weight: bold;
        line-height: 27px;
        color: #30343D;
    }
    .M2-M3-jjhj-chart{
        width: 50%;
        height: 100%;
        // overflow-x: hidden;
        margin-right: 20px;
        >#zx-chart{
            margin-top:20px;
            width: 100%; 
            height: 615px;
        }
    }
    .M2-M3-jjhj-Edit{ 
        width: 50%;
        height: 100%;
        // overflow-x: hidden;
    }
    .M2-M3-jjhj-buttonList{
        display: flex;
        margin-top: 20px;
        justify-content: flex-end;
    }
}
</style>